<template>
  <v-card :flat="true" class="rounded-xl card">
      <div class="card-body pt-4 pl-4 pr-4">
        <ServiceOfferDashboardPivotGridBkr :editEnabled="editEnabled" :dayData="selectedDayData" :service="service" :placementPolicy="placementPolicy"></ServiceOfferDashboardPivotGridBkr>
      </div>
      <v-card-actions class="pa-0">
        <div class="pb-5 pl-8 pr-8 d-flex flex-row-space-between card-footer rounded-b-xl col-12">
          <div class="d-flex align-center"><div class="seat seat-red"></div><div>{{ $t(`pages.serviceOfferBkr.legend.takenSeat`) }}</div></div>
          <div class="d-flex align-center"><div class="seat seat-blue"></div><div>{{ $t(`pages.serviceOfferBkr.legend.flexSeat`) }}</div></div>
          <div class="d-flex align-center"><div class="seat seat-yellow"></div><div>{{ $t(`pages.serviceOfferBkr.legend.reservedSeat`) }}</div></div>
          <div class="d-flex align-center"><div class="seat seat-green"></div><div>{{ $t(`pages.serviceOfferBkr.legend.availableSeat`) }}</div></div>
          <div class="d-flex align-center"><div class="seat seat-white seat-bordered"></div><div>{{ $t(`pages.serviceOfferBkr.legend.seatWithinCapacity`) }}</div></div>
        </div>
      </v-card-actions>
      <div name="buttons" v-if="canManage">
        <v-btn v-if="!editEnabled" :disabled="isLoading" @click="onEditClicked()" id="edit-button" class="edit-button" ref="edit-button" fab dark large color="primary">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
        <v-btn v-if="editEnabled" :disabled="isLoading" @click="onSaveClicked()" id="save-button" class="save-button" ref="save-button" fab dark large color="primary">
          <v-icon dark> mdi-floppy </v-icon>
        </v-btn>
        <v-btn v-if="editEnabled" :disabled="isLoading" @click="onCancelClicked()" id="cancel-button" class="cancel-button" ref="cancel-button" fab dark large color="blue">
          <v-icon dark class="partou-close"> mdi-close </v-icon>
        </v-btn>
      </div>
  </v-card>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServiceOfferDashboardPivotGridBkr from './ServiceOfferDashboardPivotGridBkr/ServiceOfferDashboardPivotGridBkr.vue'
import DailyData from '../models/DailyData.model'
import PivotGridRowData from '../models/PivotGridRowData.model'
import { Service } from '@/models'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'

@Component({
  components: { PartouCard, ServiceOfferDashboardPivotGridBkr }
})
export default class ServiceOfferDashboardBkr extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  weekData?: DailyData[]

  @Prop({ required: true })
  selectedDate?: Date

  @Prop({ required: true, default: undefined })
  service! : Service

  @Prop({ required: true, default: undefined })
  placementPolicy? : PlacementPolicy

  selectedDayData?: DailyData
  editEnabled = false
  isLoading = false
  isPageLoaded = false
  isSaving = false

  get canManage () : boolean {
    return this.authService.hasPermission([Permission.ManageOffer])
  }

  @Watch('selectedDate', { immediate: true })
  onSelectedDateChanged () : void {
    const selectedDateString = moment(this.selectedDate).format('DD MMM')
    const matchingWeekdata = this.weekData && this.weekData.find(x => x.dateString === selectedDateString)

    // Select the data for the selected date if it's between Monday and Friday. Otherwise, select Monday
    this.selectedDayData = matchingWeekdata || (this.weekData && this.weekData[0])
    this.$forceUpdate() // If there is time, find out why this is necessary
  }

  onWeekDayClicked (date: Date) : void {
    this.$emit('weekdayClicked', date)
  }

  @Emit('refresh')
  onCancelClicked () : void {
    this.editEnabled = false
  }

  onEditClicked () : void {
    this.editEnabled = true
  }

  @Emit('onDataUpdated')
  onSaveClicked () : PivotGridRowData[] | undefined {
    this.isSaving = true
    this.editEnabled = false

    // update all group data
    if (this.selectedDayData) {
      return this.selectedDayData.pivotGridRowData
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

  .card {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
  }

  .save-button, .edit-button {
    position: absolute;
    right: -32px;
    top: 32px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .cancel-button {
    z-index: 6;
    position: absolute;
    right: -32px;
    top: 113px;
    color: $partou-primary-black;
    background-color: $partou-primary-white !important;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .flex-row-space-between {
    justify-content: space-between;
  }

  .seat {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 12px;

    &.seat-green {
      background: $partou-secondary-bright-green;
    }
    &.seat-blue {
      background: $partou-primary-blue
    }
    &.seat-yellow {
      background: $partou-primary-orange;
    }
    &.seat-red {
      background: $partou-primary-salmon;
    }
    &.seat-white {
      background: #FFFFFF;
    }
    &.seat-bordered {
      border: 2px solid #1D1D1B;
    }
  }

  .divider {
    border-top: 2px solid #C4C4C4;
  }
</style>
