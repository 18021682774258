<template>
  <div>
    <div class="d-flex ml-3">
      <div class="mr-3">
        <h3 class="mb-1">{{ service.name }}</h3>
        <div v-if="service.lrkCapacity == undefined || service.lrkCapacity == 0" class="d-flex pt-1">
          <v-tooltip bottom end>
            <template v-slot:activator="{ on, attrs }">
              <v-icon ref="unkown-subscription-icon" v-bind="attrs" v-on="on" class="exclamation-mark-circled">$vuetify.icons.values.partouExclamationMarkCircledThinError</v-icon>
            </template>
            <p class="text-center white--text mt-4">{{ $t(`pages.serviceOfferBkr.invalidLrkCapcity`) }}</p>
          </v-tooltip>
          <h3 class="error-text ml-2">{{ $t(`pages.serviceOfferBkr.lrkCapacity`) }} {{ service.lrkCapacity }}</h3>
        </div>
        <p v-else>{{ $t(`pages.serviceOfferBkr.lrkCapacity`) }} {{ service.lrkCapacity }}</p>
      </div>
      <v-divider vertical/>
      <div class="ml-3 mr-3">
        <h3 class="mb-1">{{ $t(`pages.serviceOfferBkr.bso`) }}</h3>
        <div class="d-flex">
          <p> {{ $t(`pages.serviceOfferBkr.totalPlanCapacity`) }} {{ getTotalPlanCapacity('NSO') }}</p>
          <p class='ml-3'> {{ $t(`pages.serviceOfferBkr.pmBudget`) }} {{ getTotalPmBudget('NSO') }} </p>
        </div>
      </div>
      <v-divider v-if="getServiceHasVsoGroup()" vertical/>
      <div class="ml-3 mr-3" v-if="getServiceHasVsoGroup()">
        <h3 class="mb-1">{{ $t(`pages.serviceOfferBkr.vso`) }}</h3>
        <div class="d-flex">
          <p> {{ $t(`pages.serviceOfferBkr.totalPlanCapacity`) }} {{ getTotalPlanCapacity('VSO') }}</p>
          <p class='ml-3'> {{ $t(`pages.serviceOfferBkr.pmBudget`) }} {{ getTotalPmBudget('VSO') }} </p>
        </div>
      </div>
    </div>
    <table class="col-12" v-if="dayData" summary="service-offer-pivotgrid">
      <tr>
        <th class="group-column header highlighted-border-right highlighted-border-bottom" scope="col">
          {{ $t(`pages.ServiceOffer.groups`) }}
        </th>
        <th class="age-range-column header border-right highlighted-border-bottom" scope="col">
          <div class="content">
            <p class="age-range">{{ $t(`pages.serviceOfferBkr.occupation`) }}</p>
            <!-- this needs to display all the green seats but this does not work with getTotalAvailableSeats-->
            <p class="available-seats">{{ getTotalGreenSeats() }} {{ $t(`pages.ServiceOffer.available`) }} ({{ getTotalPlanCapacity() }})</p>
          </div>
        </th>
        <th class="agerange-column header highlighted-border-bottom" scope="col">
          <h2>{{ $t(`pages.serviceOfferBkr.ageRange.title`) }}</h2>
          <p>{{ $t(`pages.serviceOfferBkr.ageRange.description`) }}</p>
        </th>
      </tr>
      <tr v-for="row in dayData.pivotGridRowData" :key="row.group.name" >
        <td class="group-column content highlighted-border-right border-bottom" >
          <div @click="showChildrenDialog(row)" class="clickable">
            <div class="group-name">{{ row.group.name }}</div>
            <div class="group-details right">
              <span :class="['capacity', 'text']">{{ $t(`pages.serviceOfferBkr.capacity`) }} {{ getAttribute(row.group, "PlanCapacity") }}</span>
              <p v-if="!getGroupIsBookable(row.group)">{{ $t(`pages.serviceOfferBkr.closedGroup`) }}</p>
            </div>
          </div>
        </td>
        <td class="age-range-column content border-right border-bottom">
          <div @click="showChildrenDialog(row)" class="clickable seats-container">
            <template  v-for="seat in getSeats(row)">
              <div
                :key="seat.key"
                class="seat"
                :class="{
                  'seat-red': seat.color === 'red',
                  'seat-blue': seat.color === 'blue',
                  'seat-yellow': seat.color === 'yellow',
                  'seat-green': seat.color === 'green',
                  'seat-bordered': seat.index < getAttribute(row.group, 'PlanCapacity'),
                  'seat-opacity-24': !getGroupIsBookable(row.group)
                }"
              />
            </template>
          </div>
        </td>
        <td class="agerange-column content border-bottom">
          <div v-if="editEnabled">
          </div>
          <div v-else-if="!getGroupIsBookable(row.group) && !editEnabled" class="group-info">
            <p class="mb-0" >{{ $t(`pages.serviceOfferBkr.closed`) }} </p>
            <div :key="contentKey" v-if="!getGroupIsBookable(row.group, true)" class="d-flex">
              <v-tooltip bottom v-if="!getGroupIsBookable(row.group, true)" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon ref="unkown-subscription-icon" v-bind="attrs" v-on="on" class="exclamation-mark-circled">$vuetify.icons.values.partouExclamationMarkCircledThinError</v-icon>
                  </template>
                  <p class="text-left white--text mb-0">{{ $t(`pages.serviceOfferBkr.notBookableReasonTooltip`) }}</p>
                  <ul>
                    <li v-for="reason in getNotBookableReasons(row.group)" v-bind:key="reason">{{ reason }}</li>
                  </ul>
              </v-tooltip>
              <h3 class="error-text ml-2">{{ $t(`pages.serviceOfferBkr.notBookableGroup`) }}</h3>
            </div>
          </div>
          <div v-else>
            <p :key="contentKey">{{ getAgeRangeForCurrentDayOfWeek(row.group) }}</p>
          </div>
        </td>
      </tr>
    </table>
    <ServiceOfferChildrenDialog v-if="childrenDialogData" :show="isChildrenDialogShown" @showChanged="onChildrenDiaglogShowChange" :value="childrenDialogData" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DailyData from '../../models/DailyData.model'
import { BookableGroupPeriod, Group, Service, ServiceVarietyName } from '@/models'
import { isBetween } from '@/utils/dateUtils'
import ServiceOfferChildrenDialog from '../../ServiceOfferDashboard/ServiceOfferChildrenDialog/ServiceOfferChildrenDialog.vue'
import PivotGridRowData from '../../models/PivotGridRowData.model'
import DayOfWeek, { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'
import GroupNotBookableReason from '@/models/enums/GroupNotBookableReason'

enum BookableGroupPeriodAttribute {
  PlanCapacity = 'PlanCapacity',
  BudgetedStaff = 'PmBudget',
  IsClosed = 'isClosed'
}

@Component({
  components: { PartouCard, ServiceOfferChildrenDialog }
})
export default class ServiceOfferDashboardPivotGridBkr extends Vue {
  @Prop({ required: true })
  dayData!: DailyData

  @Prop({ required: true, default: false })
  editEnabled!: boolean

  @Prop({ required: true, default: undefined })
  service! : Service

  isChildrenDialogShown = false
  childrenDialogData: PivotGridRowData | null = null

  contentKey = 0
  get currentDayOfWeek (): DayOfWeek {
    return parseNumberToDayOfWeek(this.dayData.dayIndex)
  }

  onChildrenDiaglogShowChange (value: boolean) : void {
    this.isChildrenDialogShown = value
  }

  getServiceHasVsoGroup () : boolean {
    return this.service.groups.some(group => group.serviceVariety?.name === ServiceVarietyName.VSO)
  }

  getAgeRangeForCurrentDayOfWeek (group: Group) : string {
    const bookableGroupPeriod = this.getBookableGroupPeriodForCurrentDayOfWeek(group)
    if (!bookableGroupPeriod) {
      return ''
    }
    return this.$t('pages.serviceOfferBkr.ageRangeInYears', { minAge: bookableGroupPeriod.minAge / 12, maxAge: bookableGroupPeriod.maxAge / 12 }).toString()
  }

  getBookableGroupPeriodForCurrentDayOfWeek (group: Group) : BookableGroupPeriod | undefined {
    if (!group.bookableGroupPeriods || group.bookableGroupPeriods.length === 0) {
      return undefined // Not bookable if there are no bookable periods
    }

    const bookableGroupPeriod = group.bookableGroupPeriods.first(period =>
      period.dayOfWeek === this.currentDayOfWeek &&
      new Date(period.validFrom) <= this.dayData.date &&
      (!period.validUntil || new Date(period.validUntil) > this.dayData.date)
    )
    return bookableGroupPeriod
  }

  getGroupIsBookable (group: Group, excludeGroupClosed = false): boolean {
    const bookableGroupPeriod = this.getBookableGroupPeriodForCurrentDayOfWeek(group)
    if (!bookableGroupPeriod) {
      return false
    }
    // Check if the current day of the week is bookable based on the dayData
    const isBookable = bookableGroupPeriod.isBookable ||
                      (excludeGroupClosed && bookableGroupPeriod.notBookableReasons.length === 1 &&
                      bookableGroupPeriod.notBookableReasons[0] === GroupNotBookableReason.GroupClosed)
    return isBookable
  }

  getNotBookableReasons (group: Group): string[] {
    const notBookableReasons : string[] = []
    const bookableGroupPeriod = this.getBookableGroupPeriodForCurrentDayOfWeek(group)
    if (!bookableGroupPeriod) {
      return notBookableReasons
    }

    // Return the notBookableReasons if the period is found, otherwise return an empty array, index is requiered for the for loop and does not have to be used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, value] of bookableGroupPeriod.notBookableReasons.entries() ?? []) {
      if (value === GroupNotBookableReason.GroupClosed) {
        continue
      }
      const translationString = this.$i18n.t(`pages.serviceOfferBkr.notBookableReasons.${value}`).toString()
      notBookableReasons.push(translationString)
    }

    return notBookableReasons
  }

  getGreenSeatsCount (data: PivotGridRowData) {
    const seats = this.getSeats(data)
    return seats.filter(seat => seat.color === 'green').length
  }

  getTotalGreenSeats () {
    let totalGreenSeats = 0
    this.dayData.pivotGridRowData.forEach(row => {
      totalGreenSeats += this.getGreenSeatsCount(row)
    })

    return totalGreenSeats
  }

  getSeats (data: PivotGridRowData) {
    const seats: { key: string; color: string; index: number }[] = []
    let index = 0

    data.ageCategoryData.forEach(ageCategory => {
      for (let i = 0; i < ageCategory.occupiedSeats; i++) {
        seats.push({ key: 'occupiedSeats' + index, color: 'red', index })
        index++
      }
    })

    // Then add all blue seats
    data.ageCategoryData.forEach(ageCategory => {
      for (let i = 0; i < ageCategory.flexSeats; i++) {
        seats.push({ key: 'flexSeats' + index, color: 'blue', index })
        index++
      }
    })

    // Finally add all yellow seats
    data.ageCategoryData.forEach(ageCategory => {
      for (let i = 0; i < ageCategory.reservedSeats; i++) {
        seats.push({ key: 'reservedSeats' + index, color: 'yellow', index })
        index++
      }
    })

    const borderLimit = this.getAttribute(data.group, BookableGroupPeriodAttribute.PlanCapacity)
    if (index < borderLimit) {
      for (let i = index; i < borderLimit; i++) {
        seats.push({ key: 'greenSeats' + i, color: 'green', index: i })
      }
    }

    return seats
  }

  getTotalSeats () : number {
    const ageCategoryDataSeats = this.dayData.pivotGridColumnHeaderData.map(x => x.totalSeats)
    // Summing up all the total seats
    return ageCategoryDataSeats.reduce((total, seats) => total + seats, 0)
  }

  getTotalAvailableSeats () : number {
    const ageCategoryDataSeats = this.dayData.pivotGridColumnHeaderData.map(x => x.availableSeats)
    // Summing up all the available seats
    return ageCategoryDataSeats.reduce((total, seats) => total + seats, 0)
  }

  getTotalPlanCapacity (serviceVarietyName? : ServiceVarietyName | string): number {
    let groups = this.service.groups ?? []
    if (serviceVarietyName) {
      groups = this.service.groups.filter(group => group.serviceVariety?.name === serviceVarietyName) ?? []
    }

    return this.getTotalAttribute(groups, BookableGroupPeriodAttribute.PlanCapacity)
  }

  getTotalPmBudget (serviceVarietyName : ServiceVarietyName | string): number {
    const groups = this.service.groups.filter(group => group.serviceVariety?.name === serviceVarietyName) ?? []
    return this.getTotalAttribute(groups, BookableGroupPeriodAttribute.BudgetedStaff)
  }

  getAttribute (group: Group, attribute: BookableGroupPeriodAttribute | string): number {
    const bookableGroupPeriod = group.bookableGroupPeriods.filter((x: BookableGroupPeriod) => x.dayOfWeek === this.currentDayOfWeek && isBetween(this.dayData.date, x.validFrom, x.validUntil))[0]
    if (!bookableGroupPeriod) {
      return 0
    }

    return attribute === BookableGroupPeriodAttribute.PlanCapacity ? bookableGroupPeriod.planCapacity : bookableGroupPeriod.pmBudget
  }

  getTotalAttribute (groups: Group[], attribute: BookableGroupPeriodAttribute): number {
    return groups.reduce((total, group) => total + this.getAttribute(group, attribute), 0)
  }

  showChildrenDialog (data: PivotGridRowData) : void {
    this.childrenDialogData = data
    this.isChildrenDialogShown = true
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.exclamation-mark-circled {
    width: 20px;
    height: 20px;
  }

  .error-text{
    color: $partou-primary-salmon;
  }

  .group-column {
    text-align: left;
    padding: 16px 16px 16px 16px;
    width: 300px;

    &.header {
      color: $partou-secondary-bordeaux !important;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 20px;
    }

    &.content {
      width: 300px;
      padding: 14px 16px;
      display: block;

      .group-name {
        color: $partou-primary-black-eighty;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 8px;
      }

      .group-details {
        display: flex;
        justify-content: space-between;
        height: 21px;

        &.right {
          flex-direction: row-reverse;
        }

        .closed-group {
          width: 110px;
          color: $partou-primary-black-eighty;
        }

        .occupancy-percentage {
          display: flex;
          align-items: center;
          width: 70px;

          span {
            margin: -3px 0 0 8px;
          }
        }

        .placement-policy {
          width: 92px;
          display: flex;

          span {
            margin: 1px 0 0 8px;
          }
        }

        .capacity {
          width: 60px;
          text-align: right;
          display: flex;
          padding-top: 1px;
        }

        .text {
          color: $partou-primary-black-eighty;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }

        svg {
          &.partou-union {
            fill: $partou-primary-blue;
            margin-top: -3px;
            width: 16px;
            height: 16px;
          }

          &.partou-calendar-shine {
            fill: $partou-primary-blue;
            margin-top: 1px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .age-range-column {
    text-align: left;
    padding: 16px 16px 4px 16px;

    &.header {
      .content {
        align-items: center;

        .age-range {
          color: $partou-primary-black-eighty;
          font-size: 18px;
          font-weight: 500;
        }

        .available-seats {
          margin-left: auto;
          min-width: 66px;
          font-size: 14px;
          font-weight: 400;
          padding-top: 2px;
          color: $partou-primary-black-eighty;
        }
      }
    }

    &.content {
      padding: 8px;

      .seats-container {
        height: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;

        .seat {
          margin: 1px;
          width: 16px;
          height: 16px;
          border-radius: 2px;

          &.seat-green {
            background: $partou-secondary-bright-green;
          }
          &.seat-blue {
            background: $partou-primary-blue
          }
          &.seat-yellow {
            background: $partou-primary-orange;
          }
          &.seat-red {
            background: $partou-primary-salmon;
          }
          &.seat-white {
            background: #FFFFFF;
          }
          &.seat-light-blue {
            background: $partou-secondary-blue-fourty;
          }
          &.seat-bordered {
            border: 2px solid $partou-primary-black;
          }
          &.seat-opacity-24 {
            opacity: 0.24;
          }
        }
      }
    }
  }

  .agerange-column {
    text-align: left;
    padding: 16px 16px 16px 16px;
    width: 600px;

    &.header {
      color: $partou-secondary-bordeaux !important;
      font-size: 18px !important;
      font-weight: 500;
      line-height: 20px;
    }

    &.content {
      align-items: center;
    }
  }

  .border-right {
    border-right: 1px solid $partou-primary-black-thirty;
  }

  .highlighted-border-right {
    border-right: 1px solid $partou-primary-salmon-sixty;
  }

  .border-bottom {
    border-bottom: 1px solid $partou-primary-black-thirty;
  }

  .highlighted-border-bottom {
    border-bottom: 1px solid $partou-primary-salmon-sixty;
  }

  .clickable {
    cursor: pointer;
  }

  .group-info{
    display: flex;
    gap: 24px;
    align-items: center;
    align-text-item: center;
  }
</style>
