import { render, staticRenderFns } from "./ServiceOfferDashboardPivotGrid.vue?vue&type=template&id=05cb7710&scoped=true"
import script from "./ServiceOfferDashboardPivotGrid.vue?vue&type=script&lang=ts"
export * from "./ServiceOfferDashboardPivotGrid.vue?vue&type=script&lang=ts"
import style0 from "./ServiceOfferDashboardPivotGrid.vue?vue&type=style&index=0&id=05cb7710&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05cb7710",
  null
  
)

export default component.exports