import { AgeCategory, Reservation, ReservationStatus, Service, ServiceKind, ReservationType, FlexMargin, PincodeItem, PartOfDay } from '@/models'
import { parseAgeCategoryToAgeRange } from '@/models/enums/AgeCategory'
import { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'
import WeekInterval, { ParseWeeknumberToWeekInterval, ParseStringToWeekInterval } from '@/models/enums/WeekInterval'
import { BookableGroupPeriod, GroupPincode } from '@/models/generated/graphql'
import { ageInYears, isBetween } from '@/utils/dateUtils'
import moment from 'moment'
import DailyData from './DailyData.model'
import PivotGridColumnHeaderData from './PivotGridColumnHeaderData.model'
import PivotGridRowData from './PivotGridRowData.model'

export default class DailyDataMapper {
  getAgeCategoriesOfService (service : Service) : AgeCategory[] {
    const AgeCategories : AgeCategory[] = []
    switch (service?.kind) {
    case ServiceKind.DayCare: {
      AgeCategories.push(AgeCategory.ZeroToOne)
      AgeCategories.push(AgeCategory.OneToTwo)
      AgeCategories.push(AgeCategory.TwoToThree)
      AgeCategories.push(AgeCategory.ThreeToFour)
      break
    }
    case ServiceKind.SchoolCare: {
      AgeCategories.push(AgeCategory.FourToSeven)
      AgeCategories.push(AgeCategory.SevenPlus)
      break
    }
    }
    return AgeCategories
  }

  classifyChildInAgeCategory (referenceDate : Date, dateOfBirth : Date, serviceKind : ServiceKind) : AgeCategory {
    const age = ageInYears(dateOfBirth, referenceDate)
    if (serviceKind === ServiceKind.DayCare && age > 3) {
      return AgeCategory.ThreeToFour
    } else if (serviceKind === ServiceKind.SchoolCare && age < 4) {
      return AgeCategory.FourToSeven
    }
    switch (age) {
    case 0: {
      return AgeCategory.ZeroToOne
    }
    case 1: {
      return AgeCategory.OneToTwo
    }
    case 2: {
      return AgeCategory.TwoToThree
    }
    case 3: {
      return AgeCategory.ThreeToFour
    }
    case 4: {
      return AgeCategory.FourToSeven
    }
    case 5: {
      return AgeCategory.FourToSeven
    }
    case 6: {
      return AgeCategory.FourToSeven
    }
    default: {
      return AgeCategory.SevenPlus
    }
    }
  }

  getReservationsForDate (reservations: Reservation[], date: Date) : Reservation[] {
    const dayOfWeek = parseNumberToDayOfWeek(moment(date).isoWeekday())
    const reservationsForDate = reservations.filter((reservation) =>
      reservation.dayOfWeek === dayOfWeek &&
      this.isValidWeekInterval(reservation, date)
    )

    return reservationsForDate
  }

  isValidWeekInterval (reservation : Reservation, date : Date) : boolean {
    const weekInterval = ParseStringToWeekInterval(reservation.weekInterval)
    if (weekInterval === WeekInterval.All || weekInterval === ParseWeeknumberToWeekInterval(moment(date).isoWeek())) {
      return true
    }
    return false
  }

  calculateFlexSeatsPerAgeCategory (reservations : Reservation[] | undefined, date : Date, serviceKind : ServiceKind) : Record<AgeCategory, Reservation[]> {
    reservations = reservations?.filter((reservation) => reservation.type === ReservationType.FlexWithHours)
    return this.groupReservationsByAgeCategory(reservations, date, serviceKind)
  }

  calculateSeatsPerAgeCategory (reservations : Reservation[] | undefined, reservationStatus : ReservationStatus | ReservationStatus[], date : Date, serviceKind : ServiceKind) : Record<AgeCategory, Reservation[]> {
    const statuses = (Array.isArray(reservationStatus) ? reservationStatus : [reservationStatus]).map(x => String(x))
    reservations = reservations?.filter((reservation) => reservation.type === ReservationType.Regular && statuses.includes(reservation.status))
    return this.groupReservationsByAgeCategory(reservations, date, serviceKind)
  }

  groupReservationsByAgeCategory (reservations: Reservation[] | undefined, date : Date, serviceKind : ServiceKind) : Record<AgeCategory, Reservation[]> {
    const seatsPerAgeCategory : Record<AgeCategory, Reservation[]> = { [AgeCategory.ZeroToOne]: [], [AgeCategory.OneToTwo]: [], [AgeCategory.TwoToThree]: [], [AgeCategory.ThreeToFour]: [], [AgeCategory.FourToSeven]: [], [AgeCategory.SevenPlus]: [] }
    if (reservations) {
      for (const reservation of reservations) {
        const ageCategoryOfChild = this.classifyChildInAgeCategory(date, reservation.child?.dateOfBirth as Date, serviceKind)
        seatsPerAgeCategory[ageCategoryOfChild].push(reservation)
      }
    }
    return seatsPerAgeCategory
  }

  calculateNumberOfFlexSeatsWithPercentage (ageCategories : AgeCategory[], flexSeatsPerAgeGroup : Record<AgeCategory, Reservation[]>, flexPercentageOfDay : number) : Record<AgeCategory, number> {
    const numberOfCalculatedFlexSeatsPerAgeGroup : Record<AgeCategory, number> = { [AgeCategory.ZeroToOne]: 0, [AgeCategory.OneToTwo]: 0, [AgeCategory.TwoToThree]: 0, [AgeCategory.ThreeToFour]: 0, [AgeCategory.FourToSeven]: 0, [AgeCategory.SevenPlus]: 0 }
    const numberFlexSeatsInGroup = ageCategories.map(x => this.getNumberOfSeats(flexSeatsPerAgeGroup[x])).reduceRight((x, y) => { return x + y })
    let numberFlexSeatsToRemove = Math.floor(numberFlexSeatsInGroup * (1 - flexPercentageOfDay))
    const sortedAgeCategories = Object.values(AgeCategory).filter(x => ageCategories.includes(x)).reverse()
    sortedAgeCategories.forEach((ageCategory: AgeCategory) => {
      numberOfCalculatedFlexSeatsPerAgeGroup[ageCategory] = this.getNumberOfSeats(flexSeatsPerAgeGroup[ageCategory])
      if (numberFlexSeatsToRemove > 0) {
        const flexSeatsToRemoveFromAgeCategory = Math.min(numberFlexSeatsToRemove, numberOfCalculatedFlexSeatsPerAgeGroup[ageCategory])
        numberFlexSeatsToRemove -= flexSeatsToRemoveFromAgeCategory
        numberOfCalculatedFlexSeatsPerAgeGroup[ageCategory] = numberOfCalculatedFlexSeatsPerAgeGroup[ageCategory] - flexSeatsToRemoveFromAgeCategory
      }
    })
    return numberOfCalculatedFlexSeatsPerAgeGroup
  }

  getNumberOfSeats (reservations: Reservation[], reservationStatus?: ReservationStatus | ReservationStatus[]) : number {
    if (reservationStatus) {
      const statuses = (Array.isArray(reservationStatus) ? reservationStatus : [reservationStatus]) as ReservationStatus[]
      reservations = reservations.filter((reservation) => statuses.map(x => String(x)).includes(String(reservation.status)))
    }

    return Math.max(
      reservations.filter(x => x.partOfDay === PartOfDay.Morning).length,
      reservations.filter(x => x.partOfDay === PartOfDay.Afternoon).length
    )
  }

  buildPivotGridRowData (date: Date, ageCategories : AgeCategory[], service : Service): PivotGridRowData[] {
    const mockPivotGridRowData: PivotGridRowData[] = service.groups?.map(group => {
      const dayOfWeek = parseNumberToDayOfWeek(moment(date).isoWeekday())
      const matchingPincodes = group.groupPincodes?.filter((x: GroupPincode) => x.dayOfWeek === dayOfWeek && isBetween(date, x.validFrom, x.validUntil))
      const matchingbookableGroupPeriod = group.bookableGroupPeriods?.filter((x: BookableGroupPeriod) => x.dayOfWeek === dayOfWeek && isBetween(date, x.validFrom, x.validUntil))
      const pincodeInFuture = !matchingPincodes
        ? undefined
        : group.groupPincodes?.find((x: GroupPincode) =>
          x.dayOfWeek === dayOfWeek &&
          x.validFrom > matchingPincodes[0]?.validFrom &&
          !x.pincode.every((obj: PincodeItem, index: number) => obj.Seats === matchingPincodes[0].pincode[index].Seats)
        )

      const reservations = this.getReservationsForDate(group.reservations, date)
      const reservationsPerAgeGroup = this.calculateSeatsPerAgeCategory(reservations, [ReservationStatus.Occupied, ReservationStatus.Reserved, ReservationStatus.Option], date, service.kind as ServiceKind)
      const flexSeatsPerAgeGroup = this.calculateFlexSeatsPerAgeCategory(reservations, date, service.kind as ServiceKind)

      // disabled because expression of type 'string' can't be used to index type 'typeof DayOfWeek' but dayofweek has string indexes.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const flexPercentageOfDay = service.flexMargin[dayOfWeek as keyof FlexMargin]
      const numberOfFlexSeatsAfterPercentage = this.calculateNumberOfFlexSeatsWithPercentage(ageCategories, flexSeatsPerAgeGroup, flexPercentageOfDay)

      const sortedAgeCategories = Object.values(AgeCategory).filter(x => ageCategories.includes(x)).reverse()
      const ageCategoryData = sortedAgeCategories.map((ageCategory: AgeCategory) => {
        let maxSeats = 0
        if (service.serviceSettings[0]?.useBkrPolicy) {
          maxSeats = matchingbookableGroupPeriod.first()?.planCapacity ?? 0
          maxSeats = Math.floor(maxSeats / sortedAgeCategories.length)
        } else if (matchingPincodes && matchingPincodes[0] && matchingPincodes[0].pincode) {
          const ageRange = parseAgeCategoryToAgeRange(ageCategory)
          maxSeats = matchingPincodes[0].pincode.find((item : PincodeItem) => item.AgeRange.MinAge === ageRange.MinAge && item.AgeRange.MaxAge === ageRange.MaxAge)?.Seats ?? 0
        }

        const reservedSeats = this.getNumberOfSeats(reservationsPerAgeGroup[ageCategory], [ReservationStatus.Reserved, ReservationStatus.Option])
        const occupiedSeats = this.getNumberOfSeats(reservationsPerAgeGroup[ageCategory], ReservationStatus.Occupied)
        const flexSeats = numberOfFlexSeatsAfterPercentage[ageCategory]
        let availableSeats = maxSeats - reservedSeats - occupiedSeats - flexSeats
        if (availableSeats < 0) {
          availableSeats = 0
        }

        return {
          ageCategory,
          occupiedSeats,
          availableSeats,
          flexSeats,
          reservedSeats,
          maxSeats,
          regularReservations: reservationsPerAgeGroup[ageCategory],
          flexReservations: flexSeatsPerAgeGroup[ageCategory]
        }
      })
      ageCategoryData.reverse()
      return { group, groupPincodes: matchingPincodes?.length ? matchingPincodes : [], ageCategoryData, pincodeInFuture } as PivotGridRowData
    })

    return mockPivotGridRowData
  }

  map (service: Service, date: Date) : DailyData[] {
    const ageCategories = this.getAgeCategoriesOfService(service)
    const startDate = moment(date).isoWeekday(1)

    const dailyData : DailyData[] = []
    // generate dailyData for all dates within range
    for (let dayIndex = 0; dayIndex < 5; dayIndex++) {
      const day = moment(startDate).add(dayIndex, 'days').toDate()
      const pivotGridRowData = this.buildPivotGridRowData(day, ageCategories, service)

      const pivotGridColumnHeaderData : PivotGridColumnHeaderData[] = []

      ageCategories.forEach((ageCategory) => {
        const ageCategoryGroupData = pivotGridRowData.flatMap((data) => data.ageCategoryData.filter((ageCategoryData) => ageCategoryData.ageCategory === ageCategory))

        let totalSeats = 0
        let numberOfAvailableSeats = 0
        ageCategoryGroupData.forEach((ageCategoryData) => {
          totalSeats += ageCategoryData.maxSeats
          numberOfAvailableSeats += ageCategoryData.availableSeats
        })

        pivotGridColumnHeaderData.push({ ageCategory, availableSeats: numberOfAvailableSeats, totalSeats })
      })

      dailyData.push({
        date: day,
        dateString: moment(day).format('DD MMM'),
        dayIndex: day.getDay(),
        pivotGridRowData,
        pivotGridColumnHeaderData
      })
    }

    return dailyData
  }
}
