<template>
  <div class="col-12">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>
    <v-card :flat="true" class="rounded-xl card pa-8">
      <v-card-text>
        <h1> {{$t('services.controlVariables.settings')}} </h1>
        <h2>{{$t('services.controlVariables.planSettings')}}</h2>
        <PlanningHorizonSettings class="mb-12" v-if="canGetSettings" :planningHorizons="planningHorizonOptions" :activePlanningHorizon="activePlanningHorizon" :editEnabled="editEnabled" @onPlanningHorizonChanged="onPlanningHorizonChanged"/>
        <FlexMarginSettings v-if="canGetSettings" v-model="flexMargin" :editEnabled="editEnabled"/>

        <h2>{{$t('services.controlVariables.waitinglistManager')}}</h2>
        <WaitingListActualisationIntervalsComponent class="mb-12" v-if="canGetSettings" :waitingListActualisationIntervalsOptions="waitingListActualisationIntervalsOptions" :activeWaitingListActualisationIntervals="activeWaitingListActualisationIntervals" :editEnabled="editEnabled" @onWaitingListActualisationIntervalsChanged="onWaitingListActualisationIntervalsChanged"/>

        <h2 v-if="canGetSettings"> {{$t('services.controlVariables.offer')}}</h2>
        <ServicePricingSettings v-if="canGetSettings" v-model="servicePricings" :editEnabled="editEnabled && canManagePricing"/>
        <SubscriptionActivator v-if="canGetSettings" class="mt-10" :selectableSubscriptions="selectableSubscriptions" :servicePricings="servicePricings" :editEnabled="editEnabled && canManagePricing" @onSubscriptionChanged="onSubscriptionChanged" :service="service"/>
        <OrderProductSettings v-if="canGetSettings" v-model="showLinkToPartouOffers" :editEnabled="editEnabled"/>

        <h2>{{$t('services.controlVariables.placementPolicy.title')}}</h2>
        <PlacementPolicySettings class="mb-12" v-if="showPlacementPolicySettings && canGetSettings" :editEnabled="editEnabled" :placementPolicy="placementPolicy" @onPlacementPolicyIsValidChanged="onPlacementPolicyIsValidChanged" @onPlacementPolicyChanged="onPlacementPolicyChanged"/>
        <template v-if="canGetSettings">
          <h2>{{$t('services.controlVariables.temporarySettings')}}</h2>
          <TemporarySettings class="mb-12" v-model="isUsingFlexkidsProducts" :selectableSubscriptions="selectableSubscriptions" :servicePricings="servicePricings" :service="service" :editEnabled="editEnabled && canCallActions"/>
          <BkrPolicyFeatureToggle  v-if="getServiceKindIsBSO" v-model="useBkrPolicy" :service="service" :editEnabled="editEnabled && canManageBkrPolicy"/>
        </template>
        <template v-if="offer && canManageSettings">
          <h2>{{$t('services.controlVariables.actions')}}</h2>
          <Actions :offer="offer" @onCapacityCalculated="onCapacityCalculated"/>
        </template>
        <div name="buttons" v-if="canManageSettings || canManagePricing">
          <v-btn v-if="!editEnabled" :disabled="isLoading" @click="onEditClicked()" id="edit-button" class="edit-button" ref="edit-button" fab dark large color="primary">
            <v-icon dark> mdi-pencil </v-icon>
          </v-btn>
          <v-btn v-if="editEnabled" :disabled="isLoading || !isFormValid" @click="onSaveClickedAsync()" id="save-button" class="save-button" ref="save-button" fab dark large color="primary">
            <v-icon dark> mdi-floppy </v-icon>
          </v-btn>
          <v-btn v-if="editEnabled" :disabled="isLoading" @click="onCancelClickedAsync()" id="cancel-button" class="cancel-button" ref="cancel-button" fab dark large color="blue">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import { FlexMargin, Service, Subscription, SubscriptionService, Offer, ServiceVarietyName, ServiceKind } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'
import Delay from '@/utils/decorators/delayDecorator'
import FlexMarginSettings from '@/pages/ServicesPage/ControlVariables/FlexMargin/FlexmarginSettings.vue'
import SubscriptionActivator from './Subscriptions/SubscriptionActivator.vue'
import { SelectableSubscription } from './Subscriptions/SelectableSubscription'
import { ISubscriptionService } from '@/services/SubscriptionService/ISubscriptionService'
import ServicePricingSettings from './ServicePricing/ServicePricingSettings.vue'
import { ServicePricingExtended } from './ServicePricing/ServicePricingExtended'
import PlanningHorizonSettings from './PlanningHorizon/PlanningHorizonSettings.vue'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Actions from '@/pages/ServicesPage/ControlVariables/Actions/Actions.vue'
import TemporarySettings from '@/pages/ServicesPage/ControlVariables/TemporarySettings/TemporarySettings.vue'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import PlanSettings from '@/models/configuration/PlanSettings'
import WaitingListActualisationIntervals from '@/models/configuration/WaitingListActualisationIntervals'
import WaitingListActualisationIntervalsComponent from './WaitingListActualisationIntervals/WaitingListActualisationIntervals.vue'
import PlacementPolicy from '@/models/configuration/PlacementPolicy'
import OrderProductSettings from '@/pages/ServicesPage/ControlVariables/OrderProductSettings/OrderProductSettings.vue'
import PlacementPolicySettings from '@/pages/ServicesPage/ControlVariables/PlacementPolicySettings/PlacementPolicySettings.vue'
import { IFeatureService } from '@/services/FeatureService/IFeatureService'
import { IProductService } from '@/services/ProductService/IProductService'
import BkrPolicyFeatureToggle from '@/pages/ServicesPage/ControlVariables/BkrPolicyFeatureToggle/BkrPolicyFeatureToggle.vue'

@Component({
  components: { PartouSlider, FlexMarginSettings, SubscriptionActivator, ServicePricingSettings, PlanningHorizonSettings, Actions, TemporarySettings, WaitingListActualisationIntervalsComponent, OrderProductSettings, PlacementPolicySettings, BkrPolicyFeatureToggle }
})
export default class ControlVariables extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  @Prop({ required: true })
  serviceId!: string

  subscriptionService! : ISubscriptionService
  serviceService!: IServiceService
  offerService!: IOfferService
  serviceSettingsService!: IServiceSettingsService
  featureService!: IFeatureService

  service : Partial<Service> = {}
  selectableSubscriptions : SelectableSubscription[] = []
  servicePricings : ServicePricingExtended[] = []
  activePlanningHorizon : number | null = null
  planningHorizonOptions : number[] = []
  offer? : Partial<Offer> | null = null
  flexMargin : FlexMargin = { Monday: 0, Tuesday: 0, Wednesday: 0, Thursday: 0, Friday: 0, Saturday: 0, Sunday: 0 }
  waitingListActualisationIntervalsOptions : WaitingListActualisationIntervals[] = []
  activeWaitingListActualisationIntervals : WaitingListActualisationIntervals | null = null
  placementPolicy : PlacementPolicy | null = null
  showLinkToPartouOffers = false

  showPlacementPolicySettings = false
  isLoading = false
  editEnabled = false
  slidersKey = 0
  isUsingFlexkidsProducts = false
  isPlacementPolicyValid = true
  useBkrPolicy = false

  beforeCreate () : void {
    this.subscriptionService = container.get<ISubscriptionService>(SERVICE_IDENTIFIERS.ISubscriptionService)
    this.serviceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
    this.offerService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
    this.serviceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
    this.featureService = container.get<IFeatureService>(SERVICE_IDENTIFIERS.IFeatureService)
  }

  async mounted (): Promise<void> {
    this.showPlacementPolicySettings = await this.featureService.isFeatureEnabled('calculate-occupancy-percentage')
  }

  @Watch('servicePricings', { deep: true, immediate: true })
  onServicePricingsChanged () : void {
    if (this.servicePricings.length > 0 && this.servicePricings.filter(x => !x.isDeleted).length === 0) {
      for (const selectableSubscription of this.selectableSubscriptions) {
        selectableSubscription.subscriptionService.isAvailable = false
        selectableSubscription.isDirty = true
      }
    }
  }

  get isFormValid (): boolean {
    return this.isPlacementPolicyValid
  }

  get canGetSettings ():boolean {
    return this.authService.hasPermission([Permission.GetSetting])
  }

  get canManageSettings ():boolean {
    return this.authService.hasPermission([Permission.ManageSetting])
  }

  get canManagePricing ():boolean {
    return this.authService.hasPermission([Permission.ManagePricing])
  }

  get canCallActions ():boolean {
    return this.authService.hasPermission([Permission.CallActions])
  }

  get canManageBkrPolicy ():boolean {
    return this.authService.hasPermission([Permission.ManageBkrPolicy])
  }

  get getServiceKindIsBSO ():boolean {
    return this.service.kind === ServiceKind.SchoolCare
  }

  onFlexMarginChanged () : void {
    this.slidersKey++
  }

  @Watch('serviceId', { immediate: true })
  async fetchPageDataAsync () : Promise<void> {
    this.editEnabled = false
    this.isLoading = true
    this.getConfigurationTableDataAsync()
    await this.getServiceByIdAsync(this.serviceId)
    await this.getOfferAsync(this.serviceId)
    if (this.service.id) {
      if (this.canGetSettings) {
        this.flexMargin = this.service.flexMargin
        Object.keys(this.flexMargin).forEach((key) => {
          this.flexMargin[parseStringToDayOfWeek(key)] *= 100
        })

        await this.getActiveServiceSettings()
        const defaultSubscriptions = await this.getDefaultSubscriptions()
        this.selectableSubscriptions = this.createSelectableSubscriptions(defaultSubscriptions, await this.getSelectedSubscriptions(this.service.id))
      }
    }

    this.isLoading = false
  }

  async getOfferAsync (id: string): Promise<void> {
    this.offer = await this.offerService.getOfferByIdAsync(id)
  }

  onCapacityCalculated () : void {
    if (this.offer?.id) {
      this.getOfferAsync(this.offer.id)
    }
  }

  createSelectableSubscriptions (defaultSubscriptions : Subscription[], selectedSubscriptions? : SubscriptionService[]) : SelectableSubscription[] {
    const selectableSubscriptions : SelectableSubscription[] = []

    defaultSubscriptions.forEach(defaultSubscription => {
      const subscription = defaultSubscription
      const subscriptionService = selectedSubscriptions?.find(x => x.subscriptionId === subscription.id) ?? { serviceId: this.serviceId, subscriptionId: subscription.id, isAvailable: false }
      selectableSubscriptions.push({ subscription, subscriptionService, isDirty: false })
    })
    return selectableSubscriptions
  }

  onSubscriptionChanged (subscriptions : SelectableSubscription[]) : void {
    this.selectableSubscriptions = subscriptions
  }

  onPlanningHorizonChanged (planningHorizon : number) : void {
    this.activePlanningHorizon = planningHorizon
  }

  onWaitingListActualisationIntervalsChanged (waitingListActualisationIntervals : WaitingListActualisationIntervals) {
    this.activeWaitingListActualisationIntervals = waitingListActualisationIntervals
  }

  onPlacementPolicyIsValidChanged (isValid: boolean) {
    this.isPlacementPolicyValid = isValid
  }

  onPlacementPolicyChanged (isModeratePolicyEnabled: boolean, flexiblePolicyPercentageUpToAndIncluding?: number, moderatePolicyPercentageUpToAndIncluding?: number) {
    if (!this.placementPolicy) {
      this.placementPolicy = {} as PlacementPolicy
    }

    this.placementPolicy.isModeratePolicyEnabled = isModeratePolicyEnabled
    this.placementPolicy.flexiblePolicyPercentageUpToAndIncluding = flexiblePolicyPercentageUpToAndIncluding
    this.placementPolicy.moderatePolicyPercentageUpToAndIncluding = moderatePolicyPercentageUpToAndIncluding
  }

  async getDefaultSubscriptions () : Promise<Subscription[]> {
    return await this.subscriptionService.getDefaultSubscriptionsAsync()
  }

  async getSelectedSubscriptions (serviceId : string) : Promise<SubscriptionService[]> {
    return await this.subscriptionService.getSubscriptionWithPricingsByServiceIdAsync({ serviceId })
  }

  async getActiveServiceSettings () : Promise<void> {
    const serviceSettings = await this.serviceSettingsService.getServiceSettingsByServiceIdAsync(this.serviceId)

    this.activeWaitingListActualisationIntervals = {
      inquiryExpiresInInMinutes: serviceSettings.inquiryExpiresInInMinutes,
      sendRemainOnWaitingListMailInInMinutes: serviceSettings.sendRemainOnWaitingListMailInInMinutes,
      sendRemainOnWaitingListReminderMailInInMinutes: serviceSettings.sendRemainOnWaitingListReminderMailInInMinutes
    }
    this.activePlanningHorizon = serviceSettings?.planningHorizonInMonths
    this.isUsingFlexkidsProducts = serviceSettings?.useFlexkidsProducts
    this.showLinkToPartouOffers = serviceSettings?.showLinkToPartouOffers
    this.useBkrPolicy = serviceSettings?.useBkrPolicy

    this.placementPolicy = {
      isModeratePolicyEnabled: serviceSettings.isModeratePolicyEnabled,
      flexiblePolicyPercentageUpToAndIncluding: serviceSettings.flexiblePolicyPercentageUpToAndIncluding,
      moderatePolicyPercentageUpToAndIncluding: serviceSettings.moderatePolicyPercentageUpToAndIncluding
    }
  }

  async getServiceByIdAsync (id: string) : Promise<void> {
    if (this.canGetSettings) {
      this.service = await this.serviceService.getControlVariablesByServiceIdAsync(id)
    } else {
      this.service = await this.serviceService.getOneAsync(id)
    }

    if (this.service) {
      this.servicePricings = this.service.servicePricings?.map(x => { return { ...x, isDirty: false, isDeleted: false, isAdded: false } }) ?? []
    }
  }

  get days () : string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const resultPlanHorizonConfiguration: PlanSettings = await configurationService.getConfigurationByKeyAsync({ key: 'plan_settings', date: new Date() })
    this.planningHorizonOptions = resultPlanHorizonConfiguration.planningHorizonOptions

    const resultWaitingListActualisationIntervalsConfiguration: {waitingListActualisationIntervals: WaitingListActualisationIntervals[]} = await configurationService.getConfigurationByKeyAsync({ key: 'waiting_list_actualisation_settings', date: new Date() })
    this.waitingListActualisationIntervalsOptions = resultWaitingListActualisationIntervalsConfiguration.waitingListActualisationIntervals
  }

  async onCancelClickedAsync () : Promise<void> {
    this.editEnabled = false
    await this.fetchPageDataAsync()
  }

  onEditClicked () : void {
    this.editEnabled = true
  }

  @Delay(500)
  async onSaveClickedAsync () : Promise<void> {
    if (!this.isFormValid) {
      return
    }

    this.isLoading = true

    if (this.canManageSettings) {
      await this.updateSettingsAsync()
    }

    if (this.canManagePricing) {
      await this.updateSubscriptionServicesAsync()
      await this.updateServicePricingAsync()
    }

    if (this.canManageSettings || this.canManagePricing) {
      await this.updateServiceActivationsAsync()
    }

    this.isLoading = false
    this.editEnabled = false
    await this.fetchPageDataAsync()
  }

  async updateSettingsAsync () : Promise<void> {
    const newFlexMargin = { ...this.flexMargin }
    Object.keys(newFlexMargin).forEach((key) => {
      newFlexMargin[parseStringToDayOfWeek(key)] /= 100
    })
    await this.serviceService.setFlexMarginByServiceIdAsync({ id: this.serviceId, flexMargin: newFlexMargin })
    await this.serviceSettingsService.setPlanningHorizonByServiceIdAsync(this.serviceId, this.activePlanningHorizon ?? 0)
    await this.serviceSettingsService.setWaitingListActualisationIntervalsAsync(this.serviceId, this.activeWaitingListActualisationIntervals?.inquiryExpiresInInMinutes ?? 0, this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListMailInInMinutes ?? 0, this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListReminderMailInInMinutes ?? 0)
    await this.serviceSettingsService.setUseFlexkidsProductsAsync(this.serviceId, this.isUsingFlexkidsProducts)
    await this.serviceSettingsService.setShowLinkToPartouOffersAsync(this.serviceId, this.showLinkToPartouOffers)
    await this.serviceSettingsService.setPlacementPolicyAsync(this.serviceId, this.placementPolicy!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    await this.serviceSettingsService.setUseBkrPolicyAsync(this.serviceId, this.useBkrPolicy)
  }

  async updateSubscriptionServicesAsync () : Promise<void> {
    const dirtySubscriptions = this.selectableSubscriptions.filter(selectableSubscription => selectableSubscription.isDirty)
    for (const dirtySubscription of dirtySubscriptions) {
      if (dirtySubscription.subscriptionService && this.subscriptionService) {
        await this.subscriptionService.upsertSubscriptionServiceAsync(dirtySubscription.subscriptionService)
      }
    }

    if (this.service?.id && this.service?.isCustomSubscriptionEnabled !== undefined) {
      await this.serviceService.toggleCustomSubscriptionAsync(this.service.id, this.service.isCustomSubscriptionEnabled)
    }
  }

  async updateServicePricingAsync () : Promise<void> {
    const deletedPricings = this.servicePricings.filter(pricing => pricing.isDeleted)
    const addedPricings = this.servicePricings.filter(pricing => pricing.isAdded && !pricing.isDeleted)
    const dirtyPricings = this.servicePricings.filter(pricing => pricing.isDirty && pricing.id && !pricing.isDeleted)

    for (const deletedPricing of deletedPricings) {
      if (this.subscriptionService && deletedPricing.id) {
        await this.subscriptionService.deletedServicePricingAsync({ id: deletedPricing.id })
      }
    }

    for (const addedPricing of addedPricings) {
      if (this.subscriptionService && this.serviceId) {
        await this.subscriptionService.insertSubscriptionPricingAsync({ hourRate: addedPricing.hourRate, serviceId: this.serviceId, validFrom: addedPricing.validFrom, validUntil: addedPricing.validUntil })
      }
    }

    for (const dirtyPricing of dirtyPricings) {
      if (this.subscriptionService && dirtyPricing.id) {
        await this.subscriptionService.updateServicePricingByIdAsync({ id: dirtyPricing.id, validFrom: dirtyPricing.validFrom, validUntil: dirtyPricing.validUntil })
      }
    }
  }

  // Disable activations that depend on subscriptions when there are no available subscriptions.
  async updateServiceActivationsAsync (): Promise<void> {
    if (!this.isUsingFlexkidsProducts && this.selectableSubscriptions.every(subscription => subscription.subscriptionService.isAvailable === false)) {
      await this.serviceSettingsService.setBookableInSelectionGuideAsync(this.serviceId, false)
      await this.serviceSettingsService.setAllowDirectPlacementAsync(this.serviceId, false)
      await this.serviceService.disableWaitingListAutomationAsync(this.serviceId)
      await this.serviceSettingsService.setAvailableInCalculatorAsync(this.serviceId, false)
    }
    if (this.isUsingFlexkidsProducts) {
      const products = await this.productService.getActiveBenefitCalculatorProducts(this.serviceId)
      if ((this.service.kind === ServiceKind.SchoolCare && products.filter(p => p.product?.serviceVariety === ServiceVarietyName.NSO).length === 0) || (this.service.kind === ServiceKind.DayCare && products.length === 0)) {
        await this.serviceSettingsService.setAvailableInCalculatorAsync(this.serviceId, false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

  h2 {
    color: $partou-secondary-bordeaux !important;
    margin: 48px 0;
  }

 .card {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
  }

  .save-button, .edit-button {
    position: absolute;
    right: -32px;
    top: 88px;
  }

  .cancel-button {
    z-index: 6;
    position: absolute;
    right: -32px;
    top: 162px;
  }
</style>
